import { createAction } from '@ngrx/store'
import {
  UpdateCostSavingsDataByDatePayload,
  UpdateCostSavingsDataByDateResponsePayload,
} from '../../../_core/models/fleet-account'

export const siteSettingsTabActions = {
  UPDATE_COST_SAVINGS_DATA_BY_DATE: createAction(
    '[Site Settings Tab] Update Cost Savings Data By Date',
    (payload: UpdateCostSavingsDataByDatePayload) => ({ payload })
  ),

  UPDATE_COST_SAVINGS_DATA_BY_DATE_SUCCESS: createAction(
    '[Site Settings Tab] Update Cost Savings Data By Date Success',
    (payload: UpdateCostSavingsDataByDateResponsePayload) => ({ payload })
  ),

  UPDATE_COST_SAVINGS_DATA_BY_DATE_ERROR: createAction(
    '[Site Settings Tab] Update Cost Savings Data By Date Error'
  ),
}
